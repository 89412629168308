import React, { useState } from "react";
import styles from "../../styles/styles";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

const Checkout = () => {
  // const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  const [user, setUser] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [userInfo, setUserInfo] = useState(false);
  // const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  // const [zipCode, setZipCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [totalPriceWithDelivery, setTotalPriceWithDelivery] = useState(0);
  const [locations, setLocations] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to fetch delivery fee based on location
  const fetchDeliveryFee = async (location) => {
    try {
      const response = await axios.get(`${server}/delivery-fee/${location}`);
      const fee = response.data.deliveryFee;

      setDeliveryFee(fee);

      // Recalculate total price with the new delivery fee and update the state
      
      // 
      setTotalPriceWithDelivery(parseFloat((totalPrice + fee).toFixed(2)));


      

    } catch (error) {
      // console.error('Error fetching delivery fee:', error.message);
      // Reset delivery fee to 0 in case of an error or if the location is not found
      setDeliveryFee(0);
      setTotalPriceWithDelivery(Number(totalPrice));
    }
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${server}/locations`);
        setLocations(response.data);
        // console.log(response.data)
      } catch (error) {
        console.error("Error fetching locations:", error);
        // Handle error appropriately
      }
    };

    fetchLocations();
  }, []);

  // Update the address2 state and fetch delivery fee
  const handleLocationChange = (e) => {
    const newLocation = e.target.value;
    setAddress2(newLocation);
    fetchDeliveryFee(newLocation);
  };

  const paymentSubmit = () => {
    if (
      // address1 === "" ||
      address2 === "" ||
      // zipCode === null ||
      country === "" ||
      city === "" ||
      phoneNumber === "" ||
      user === ""
    ) {
      toast.error("Please choose your delivery address!");
    } else {
      const shippingAddress = {
        // address1,
        address2,
        // zipCode,
        country,
        city,
        phoneNumber,
        email,
        user,
      };

      const orderData = {
        cart,
        totalPrice,
        subTotalPrice,
        shipping,
        discountPrice,
        shippingAddress,
        user,
        deliveryFee,
        totalPriceWithDelivery: parseFloat(totalPriceWithDelivery.toFixed(2))
      };

      // update local storage with the updated orders array
      localStorage.setItem("latestOrder", JSON.stringify(orderData));
      navigate("/payment");
    }
  };

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  // this is shipping cost variable
  const shipping = 0;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = couponCode;

    await axios.get(`${server}/coupon/get-coupon-value/${name}`).then((res) => {
      const shopId = res.data.couponCode?.shopId;
      const couponCodeValue = res.data.couponCode?.value;
      if (res.data.couponCode !== null) {
        const isCouponValid =
          cart && cart.filter((item) => item.shopId === shopId);

        if (isCouponValid.length === 0) {
          toast.error("Coupon code is not valid for this shop");
          setCouponCode("");
        } else {
          const eligiblePrice = isCouponValid.reduce(
            (acc, item) => acc + item.qty * item.discountPrice,
            0
          );
          const discountPrice = (eligiblePrice * couponCodeValue) / 100;
          setDiscountPrice(discountPrice);
          setCouponCodeData(res.data.couponCode);
          setCouponCode("");
        }
      }
      if (res.data.couponCode === null) {
        toast.error("Coupon code doesn't exists!");
        setCouponCode("");
      }
    });
  };

  const discountPercentenge = couponCodeData ? discountPrice : "";

  // const totalPrice = couponCodeData
  //   ? (subTotalPrice + shipping - discountPercentenge).toFixed(2)
  //   : (subTotalPrice + shipping).toFixed(2);
  const totalPrice = couponCodeData
    ? Number((subTotalPrice + shipping - discountPercentenge).toFixed(2))
    : Number((subTotalPrice + shipping).toFixed(2));


  // console.log(discountPercentenge);

  return (
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <ShippingInfo
            user={user}
            setUser={setUser}
            country={country}
            setCountry={setCountry}
            city={city}
            setCity={setCity}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            // address1={address1}
            // setAddress1={setAddress1}
            address2={address2}
            setAddress2={setAddress2}
            // zipCode={zipCode}
            // setZipCode={setZipCode}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            email={email}
            setEmail={setEmail}
            handleLocationChange={handleLocationChange}
            locations={locations}
            
          />
        </div>
        <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
          <CartData
            handleSubmit={handleSubmit}
            // totalPrice={totalPrice}
            totalPriceWithDelivery={totalPriceWithDelivery}
            // shipping={shipping}
            deliveryFee={deliveryFee}
            subTotalPrice={subTotalPrice}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            discountPercentenge={discountPercentenge}
          />
        </div>
      </div>
      <div
        className={`${styles.button} w-[150px] 800px:w-[280px] mt-10`}
        onClick={paymentSubmit}
      >
        <h5 className="text-white">Go to Payment</h5>
      </div>
    </div>
  );
};

const ShippingInfo = ({
  user,
  setUser,
  country,
  setCountry,
  city,
  setCity,
  userInfo,
  setUserInfo,
  // address1,
  // setAddress1,
  address2,
  setAddress2,
  // zipCode,
  // setZipCode,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  handleLocationChange,
  locations,
 
}) => {
  return (
    <div className="w-full 800px:w-[95%] bg-white rounded-md p-5 pb-8">
      <h5 className="text-[18px] font-[500]">Delivery Address</h5>
      <br />
      <form>
        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2">Full Name</label>
            <input
              type="text"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              required
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          <div className="w-[50%]">
            <label className="block pb-2">Email Address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={`${styles.input}`}
            />
          </div>
        </div>

        <div className="w-full flex pb-3">
          {/* <div className="w-[50%]">
            <label className="block pb-2">Phone Number</label>
            <input
              type="number"
              required
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className={`${styles.input} !w-[95%]`}
            />
          </div> */}
          {/* <div className="w-[50%]">
            <label className="block pb-2">Zip Code</label>
            <input
              type="number"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
              className={`${styles.input}`}
            />
          </div> */}
        </div>

        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2">Country</label>
            <select
              className="w-[95%] border h-[40px] rounded-[5px]"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option className="block pb-2" value="">
                Choose your country
              </option>
              {Country &&
                Country.getAllCountries().map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="w-[50%]">
            <label className="block pb-2">City</label>
            <select
              className="w-[95%] border h-[40px] rounded-[5px]"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              <option className="block pb-2" value="">
                Choose your City
              </option>
              {State &&
                State.getStatesOfCountry(country).map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="w-full flex pb-3">
          {/* <div className="w-[50%]">
            <label className="block pb-2">Address</label>
            <input
              type="address"
              required
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              className={`${styles.input} !w-[95%]`}
            />
          </div> */}
          {/* <div className="w-[50%]">
            <label className="block pb-2">Location</label>
            <input
              type="text"
              value={address2}
              onChange={handleLocationChange}
              required
              className={`${styles.input}`}
            />
          </div> */}
          
          <div className="w-[50%]">
            <label className="block pb-2">Location</label>
            <select
              value={address2}
              onChange={handleLocationChange}
              required
              className={`${styles.input}`}
            >
              <option value="">Select Location</option>
              {locations.map((location) => (
                <option key={location._id} value={location.zoneName}>
                  {location.zoneName}
                </option>
              ))}
            </select>
          </div>
          <div className="w-[50%]">
            <label className="block pb-2">Phone Number</label>
            <input
              type="number"
              required
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className={`${styles.input} !w-[95%]`}
            />
          </div>
        </div>

        <div></div>
      </form>
      <h5
        className="text-[18px] cursor-pointer inline-block"
        onClick={() => setUserInfo(!userInfo)}
      >
        Choose From saved address
      </h5>
      {userInfo && (
        <div>
          {user &&
            user.addresses.map((item, index) => (
              <div className="w-full flex mt-1">
                <input
                  type="checkbox"
                  className="mr-3"
                  value={item.addressType}
                  onClick={() =>
                    // setAddress1(item.address1) ||
                    setAddress2(item.address2) ||
                    // setZipCode(item.zipCode) ||
                    setCountry(item.country) ||
                    setCity(item.city) ||
                    setPhoneNumber(item.phoneNumber) ||
                    setEmail(item.email) ||
                    setUser(item.user)
                  }
                />
                <h2>{item.addressType}</h2>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const CartData = ({
  handleSubmit,
  // totalPrice,
  // shipping,
  subTotalPrice,
  couponCode,
  setCouponCode,
  discountPercentenge,
  totalPriceWithDelivery,
  deliveryFee,
}) => {
  return (
    <div className="w-full bg-[#fff] rounded-md p-5 pb-8">
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">subtotal:</h3>
        <h5 className="text-[18px] font-[600]">GH₵{subTotalPrice}</h5>
      </div>
      <br />
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">delivery:</h3>
        <h5 className="text-[18px] font-[600]">GH₵{deliveryFee}</h5>
      </div>
      <br />
      <div className="flex justify-between border-b pb-3">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Discount:</h3>
        <h5 className="text-[18px] font-[600]">
          -{" "}
          {discountPercentenge ? "GH₵ " + discountPercentenge.toString() : null}
        </h5>
      </div>
      <h5 className="text-[18px] font-[600] text-end pt-3">
        GH₵{totalPriceWithDelivery}
      </h5>
      <br />
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className={`${styles.input} h-[40px] pl-2`}
          placeholder="Coupoun code"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          required
        />
        <input
          className={`w-full h-[40px] border border-[#f63b60] text-center text-[#f63b60] rounded-[3px] mt-8 cursor-pointer`}
          required
          value="Apply code"
          type="submit"
        />
      </form>
    </div>
  );
};

export default Checkout;
