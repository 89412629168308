import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

const Hero = () => {
  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[100vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        
       
        backgroundImage:
          "url(https://images.unsplash.com/photo-1504674900247-0877df9cc836?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#F8F8F8] font-[600] capitalize font-extrabold`}
        >
          Fresh, Fast, Flavorful <br /> Delivered to You
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#F8F8F8] font-normal">
          Experience the fusion of culinary art and convenience with  
          our food delivery service. <br /> Savor a world of flavors, freshly prepared and swiftly delivered to your doorstep. {" "}
          <br /> Indulge in the luxury of fine dining at home.
        </p>
        <Link to="/products" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Shop Now
                 </span>
            </div>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
